<template>
  <main class="kb-main" id="kb-myclass-ref">
    <lxp-main-header :show-title="true" :show-back="true" :title="lib.postTitle">
      <template v-slot:inner v-if="lib.postFileAtchYn === 'Y' && files.length > 0">
        <div class="header-column">
          <div class="kb-form-dropdown download-dropdown" :class="{'is-active': showAttachments}" data-offset="bottom-right" @click="showAttachments = !showAttachments">
            <button class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><i class="icon-download32"></i><span class="text">첨부파일</span></button>
            <div class="kb-form-dropdown-options">
              <div class="dropdown-option">
                <ul class="dropdown-option-list" v-for="(file,idx) in files" :key="idx">
                  <li class="dropdown-option-item">
                    <a href="javascript:" class="dropdown-option-link" :title="file.fileNm" @click.stop="downloadFile(file)">
                      <span class="dropdown-option-text">{{ (file.fileNm != null ? file.fileNm : '-') }}</span>
                      <span class="download-mb">{{ (file.fileSz > 0 ? convertToStorageBytes(file.fileSz) : 0)   }}KB</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </lxp-main-header>

    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container">
        <div class="view-header">
          <h3 class="title">{{ lib.postTitle }}</h3>
          <p class="subtitle">{{ timestampToDateFormat(lib.regDt, 'yyyy.MM.dd hh:mm') }} 등록</p>
        </div>
        <div class="view-body min-component">
          <div class="view-content" v-html="lib.postCn">
          </div>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {ACT_GET_HELP_FILE_ATCH_LIST, ACT_GET_HELP_LIB} from '@/store/modules/help/help';
import {
  getItem,
  getItems,
  getText,
  lengthCheck,
  timestampToDateFormat,
  convertToStorageBytes,
  commonDownloadFile
} from '@/assets/js/util';
import {downloadAtch} from "@/assets/js/modules/help/help-common";
import {isInApp, isInAppIOS} from "@/assets/js/navigationUtils";

export default {
  name: 'HelpLibView',
  components: {LxpMainHeader},
  setup(){
    const store = useStore();
    const route = useRoute();

    const showAttachments = ref(false);

    const comPostSn = computed(() => route.params.comPostSn);

    const lib = ref({});
    const files = ref([]);

    const getHelpLib = () => {
      if (route.params.comPostSn > 0) {
        store.dispatch(`help/${ACT_GET_HELP_LIB}`, comPostSn.value
        ).then(res => {
          if (lengthCheck(res)) {
            lib.value = getItem(res);
            getHelpNoticeFileAtchList();
          }
        }).catch(e => {
          console.error(e);
        })
      }
    };

    const getHelpNoticeFileAtchList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FILE_ATCH_LIST}`, {
        comPostSn: comPostSn.value
      }).then(res => {
        if (lengthCheck(res)) {
          files.value = getItems(res);
        } else {
          files.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const downloadFile = (atch) => {
      if(isInApp && isInAppIOS) {

        commonDownloadFile(atch);
        // if (atch.buketNm === 'pea.hrd.aws.prd.s3.contents') {
        //   winOpen(`https://lxpcms.kbstar.com/${atch.upldLoc}`, '_blank');
        // } else if (atch.buketNm === 'pea.hrd.aws.dev.s3.contents') {
        //   winOpen(`https://devlxpcms.kbstar.com/${atch.upldLoc}`, '_blank');
        // }

      }else{
        downloadAtch(atch)
      }
    }

    getHelpLib();

    return {
      showAttachments,
      lib,
      files,
      downloadFile,
      downloadAtch,
      timestampToDateFormat,
      getText,
      convertToStorageBytes
    }

  }
}
</script>
<style type="text/css">
.view-content p{
  font: unset;
}
</style>